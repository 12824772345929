<template>
  <section>
    <add-question-side-bar
      :form-id="surveyId"
      :user-roles="userRolesSelected"
      :form-type="getFormType"
      :question-type="questionTypeComp"
      :open="openAddSideBar"
      :title="sideBarTitle"
      :is-updating="isUpdating"
      @toggleSideBar="toggleAddSideBar"
      @submit="createSurveyQuestion"
    />
    <edit-question-side-bar
      :form-id="surveyId"
      :question-id="editQuestionId"
      :question-type="questionType"
      :form-type="getFormType"
      :open="openEditSideBar"
      :is-updating="isUpdating"
      @toggleSideBar="toggleEditSideBar"
      @update="updateSurveyQuestion"
    />
    <validation-observer
      ref="observer"
      v-slot="{ handleSubmit }"
    >
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <b-card>
          <b-row>
            <b-col sm="12">
              <question-skeleton-loader v-if="isFetching" />
              <b-row v-else>
                <b-col sm="9">
                  <h5 class="mt-2">
                    Survey - Details
                  </h5>
                </b-col>
                <b-col sm="3">
                  <add-question-button
                    hide-matching-questions
                    @addQuestionType="showQuestionAdd"
                  />
                </b-col>
              </b-row>
              <b-row v-if="survey">
                <b-col sm="6">
                  <h5>Title:</h5>
                  {{ survey.translations[0].title }}

                  <h5 class="mt-1">
                    Role:
                  </h5>
                  <span
                    v-for="(role, index) in survey.roles"
                    :key="index"
                  >
                    {{ userRolesDisplay[role.id] }}
                  </span>
                </b-col>
                <b-col sm="6">
                  <h5>Description:</h5>
                  {{ survey.translations[0].description }}
                  <h5 class="mt-1">
                    When to send:
                  </h5>
                  <div
                    v-for="(action) in survey.scheduled_actions"
                    :key="action.id"
                  >
                    <span>
                      {{ getAvailability(action) }}
                    </span>
                  </div>
                </b-col>
              </b-row>
              <draggable
                v-model="surveyQuestions"
                class="mt-2"
              >
                <b-card
                  v-for="(question, index) in surveyQuestions"
                  :key="question.id"
                  no-body
                  :class="question.is_locked && !isAdminPortal? 'mb-1 disabled': 'mb-1'"
                >
                  <b-card-header
                    header-tag="header"
                    class="p-1"
                    role="tab"
                    :style="{ 'background-color': question.background }"
                  >
                    <b-col sm="2">
                      <feather-icon
                        class="move-icon"
                        icon="GridIcon"
                        size="15"
                      />
                      <span class="ml-1">{{ index + 1 }}.</span>
                    </b-col>
                    <b-col sm="7">
                      <span>{{ question.translations[0].question_text }}</span>
                    </b-col>
                    <b-col
                      sm="3"
                      class="flex-end d-flex justify-content-end"
                    >
                      <b-button
                        variant="link"
                        class="py-0 pr-1 pl-0"
                        size="sm"
                        @click="isAdminPortal? lockOrUnlockQuestion(question): false"
                      >
                        <feather-icon
                          v-b-tooltip.hover.top="!isAdminPortal && question.is_locked? 'This question cannot be edited': null"
                          :icon="question.is_locked ? 'LockIcon': 'UnlockIcon'"
                          size="15"
                        />
                      </b-button>
                      <b-button
                        :disabled="!isAdminPortal && question.is_locked"
                        size="sm"
                        class="py-0 pr-1 pl-0"
                        variant="link"
                        @click="editQuestion(question)"
                      >
                        <feather-icon
                          icon="EditIcon"
                          size="15"
                        />
                      </b-button>
                      <b-button
                        :disabled="!isAdminPortal && question.is_locked"
                        size="sm"
                        class="py-0 pr-1 pl-0"
                        variant="link"
                        @click="deleteQuestion(question)"
                      >
                        <feather-icon
                          icon="TrashIcon"
                          size="20"
                        />
                      </b-button>

                      <b-button
                        v-b-toggle="`accordion-${question.id}`"
                        size="sm"
                        class="p-0"
                        variant="link"
                      >
                        <feather-icon
                          icon="ChevronUpIcon"
                          class="when-opened"
                          size="20"
                        />
                        <feather-icon
                          icon="ChevronDownIcon"
                          class="when-closed"
                          size="20"
                        />
                      </b-button>
                    </b-col>
                  </b-card-header>
                  <b-collapse
                    :id="`accordion-${question.id}`"
                    visible
                    accordion="mentor-accordion"
                    role="tabpanel"
                  >
                    <b-card-body
                      :class="{
                        'overflow-scroll':
                          question.type_id === questionTypes.SCALE,
                      }"
                    >
                      <div v-if="question.type_id === questionTypes.TEXT">
                        <label class="mt-1">Info text</label>
                        <b-form-group label-for="prefilled-answer">
                          <b-form-textarea
                            :value="question.translations[0].prefilled_answer"
                            disabled
                            placeholder="Prefilled answer"
                            maxlength="255"
                            rows="3"
                          />
                        </b-form-group>
                      </div>
                      <div v-if="question.type_id === questionTypes.CHOICE">
                        <div
                          v-for="(choice, i) in question.choices"
                          :key="i"
                        >
                          <b-row>
                            <b-col md="12">
                              <b-row>
                                <b-col md="1">
                                  <b-form-radio
                                    disabled
                                    name="question-options"
                                    class="mt-1"
                                  />
                                </b-col>
                                <b-col md="11">
                                  <b-form-input
                                    v-if="question.choices[i].translations"
                                    :value="
                                      question.choices[i].translations[0]
                                        .choice_text
                                    "
                                    disabled
                                    class="mt-1"
                                    type="text"
                                    :placeholder="`Option ${i + 1}`"
                                  />
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </div>
                      </div>
                      <div v-if="question.type_id === questionTypes.SCALE">
                        <b-row
                          v-for="indexRow in question.statements.length + 1"
                          :key="indexRow"
                          class="mt-1 flex-nowrap"
                        >
                          <b-col
                            v-for="indexCol in question.choices.length + 2"
                            :key="indexCol"
                            class="col-sm-3"
                          >
                            <div
                              v-if="
                                indexRow === 1 &&
                                  indexCol === question.choices.length + 2
                              "
                              class="minimal-width"
                            />

                            <div
                              v-if="
                                indexRow === 1 &&
                                  indexCol > 1 &&
                                  indexCol < question.choices.length + 2
                              "
                            >
                              <b-input-group
                                :label-for="'option' + (indexCol - 1)"
                              >
                                <b-form-textarea
                                  v-if="
                                    question.choices[indexCol - 2].translations
                                  "
                                  disabled
                                  :value="
                                    question.choices[indexCol - 2]
                                      .translations[0].choice_text
                                  "
                                  type="text"
                                  class="min-statement-width"
                                  :placeholder="'Option ' + (indexCol - 1)"
                                  style="overflow: hidden"
                                  size="sm"
                                  no-resize
                                />
                              </b-input-group>
                            </div>
                            <div v-else-if="indexRow > 1 && indexCol === 1">
                              <b-input-group
                                :label-for="'statement' + (indexRow - 1)"
                              >
                                <b-form-textarea
                                  v-if="
                                    question.statements[indexRow - 2]
                                      .translations[0]
                                  "
                                  :value="
                                    question.statements[indexRow - 2]
                                      .translations[0].question_text
                                  "
                                  disabled
                                  class="min-statement-width"
                                  type="text"
                                  :placeholder="`Statement ${indexRow - 1}`"
                                  style="overflow: hidden"
                                  size="sm"
                                  no-resize
                                />
                              </b-input-group>
                            </div>
                            <div
                              v-else-if="
                                indexRow > 1 &&
                                  indexCol > 1 &&
                                  indexCol < question.choices.length + 2
                              "
                            >
                              <b-form-radio
                                disabled
                                name="option-radio"
                                class="ml-3"
                              />
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </draggable>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm="6"
              md="3"
              class="mb-1"
            >
              <b-button
                block
                type="reset"
                variant="outline-dark"
                :disabled="isButtonsDisabled"
                @click="redirectBack"
              >
                Cancel
              </b-button>
            </b-col>
            <b-col
              sm="6"
              md="3"
              class="mb-1"
            >
              <b-button
                block
                type="submit"
                :disabled="isButtonsDisabled"
                variant="primary"
              >
                <b-spinner
                  v-if="isUpdating"
                  small
                />
                <span v-if="isUpdating"> Updating...</span>
                <span v-else>Submit</span>
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-form>
    </validation-observer>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCollapse,
  BCardHeader,
  BButton,
  BSpinner,
  BForm,
  VBToggle,
  BCardBody,
  BFormTextarea,
  BFormGroup,
  BFormRadio,
  BFormInput,
  BInputGroup,
  VBTooltip
} from "bootstrap-vue";

import AddQuestionButton from "@components/questions/AddQuestionButton.vue";
import { ValidationObserver } from "vee-validate";
import { makeSuccessToast, makeErrorToast } from "@/libs/utils";
import { questionTypes } from "@models/questionTypes";
import AddQuestionSideBar from "@components/questions/AddQuestionSideBar.vue";
import EditQuestionSideBar from "@components/questions/EditQuestionSideBar.vue";
import draggable from "vuedraggable";
import { mapMutations } from "vuex";
import { formTypes } from "@models/formTypes";
import formsService from "@/services/formsService";
import QuestionSkeletonLoader from "../../questions/QuestionSkeletonLoader.vue";
import { actionableEvent } from "@/models/actionableEvent";
import { localeDateStringFromIsoDateTime } from "@/libs/utils";
import { userRolesDisplay } from "@models/userRoles";
import { mapGetters } from "vuex";
import { useGetAvailability } from "@/views/apps/useGetAvailability";

export default {
  name: "SurveyForm",
  components: {
    BCard,
    BRow,
    BCol,
    BCardBody,
    AddQuestionButton,
    AddQuestionSideBar,
    BButton,
    BSpinner,
    BCardHeader,
    BCollapse,
    BFormTextarea,
    BForm,
    draggable,
    ValidationObserver,
    BFormGroup,
    BFormRadio,
    BFormInput,
    QuestionSkeletonLoader,
    BInputGroup,
    EditQuestionSideBar,
  },
  directives: {
    'b-toggle': VBToggle,
    "b-tooltip": VBTooltip,
  },
  props: {
    viewMode: {
      type: String,
      default: "admin",
    },
  },
  data() {
    return {
      isUpdating: false,
      isFetching: false,
      surveyQuestions: [],
      survey: undefined,
      surveyId: this.$route.params.survey,
      questionTypeComp: undefined,
      userRolesSelected: [],
      openAddSideBar: false,
      questionTypes,
      openEditSideBar: false,
      editQuestionId: undefined,
      editQuestionType: undefined,
      sideBarTitle: this.title,
      questionType: undefined,
      actionableEvent,
      localeDateStringFromIsoDateTime,
      userRolesDisplay,
    };
  },
  computed: {
    ...mapGetters("app", ["isChampionPortal", "isAdminPortal"]),
    getFormType() {
      return { id: formTypes.SURVEY, name: "Survey" };
    },
    isButtonsDisabled() {
      return this.survey?.questions.length === 0;
    },
  },
  watch: {
    surveyQuestions: {
      handler(n) {
        const updateSort = n.map((q, i) => ({ ...q, question_order: i }));
        this.survey = {
          ...this.survey,
          questions: updateSort,
        };
      },
      deep: true,
    },
  },
  created() {
    this.fetchSurvey();
  },
  methods: {
    resolveFetchService() {
      return this.isChampionPortal
        ? formsService.getProgramSurvey(this.$route.params.id, this.surveyId)
        : formsService.getSurvey(this.surveyId);
    },
    resolveUpdateService(id, questions) {
      return this.isChampionPortal
        ? formsService.updateProgramSurvey(this.$route.params.id, id, {
            questions: questions,
          })
        : formsService.updateSurvey(id, { questions: questions });
    },
    async onSubmit() {
      const { id, questions } = this.survey;
      try {
        this.isUpdating = true;
        await this.resolveUpdateService(id, questions);
        this.$toast(makeSuccessToast("Survey Form Updated Successfully."));
      } catch (e) {
        const { data } = e.response;
        this.$log.error(e);
        this.$toast(makeErrorToast(data.message));
      } finally {
        this.isUpdating = false;
      }
    },
    async fetchSurvey() {
      try {
        this.isFetching = true;
        const response = await this.resolveFetchService();
        const { data } = response;
        this.surveyQuestions = data.questions;
        this.survey = data;
      } catch (e) {
        const { data } = e.response;
        this.$log.error(e);
        this.$toast(makeErrorToast(data.message));
      } finally {
        this.isFetching = false;
      }
    },
    clearForm() {
      this.CLEAR_QUESTION();
      this.openAddSideBar = false;
      this.openEditSideBar = false;
      this.editQuestionId = undefined;
      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
    showQuestionAdd(qType) {
      const { type, roles } = qType;
      if (type.value === questionTypes.TEXT) {
        this.questionTypeComp = "AddSingleTextBoxQuestionForm";
        this.sideBarTitle = "Add New Question - Single Text Box";
        this.userRolesSelected = roles;
        this.openAddSideBar = true;
      }
      if (type.value === questionTypes.CHOICE) {
        this.questionTypeComp = "AddMultipleChoiceQuestionForm";
        this.sideBarTitle = "Add New Question - Multi Choice";
        this.userRolesSelected = roles;
        this.openAddSideBar = true;
      }
      if (type.value === questionTypes.STATEMENT) {
        this.questionTypeComp = "AddRatingScalesQuestionForm";
        this.sideBarTitle = "Add New Question - Rating Scale";
        this.userRolesSelected = roles;
        this.openAddSideBar = true;
      }
    },
    editQuestion(question) {
      const { id } = question;
      this.openEditSideBar = true;
      this.editQuestionId = id;
    },
    async lockOrUnlockQuestion(question) {
      try {
        const isLocked = !question.is_locked;
        const formId = this.$route.params.survey;
        const response = await this.updateQuestion(formId, question.id, { is_locked: isLocked });
        const { data } = response;
        question.is_locked = data.is_locked;
        this.$toast(makeSuccessToast('Question ' + (question.is_locked ? 'Locked' : 'Unlocked') + ' successfully.'));
      } catch (e) {
        const { data } = e.response;
        this.$log.error(e);
        this.$toast(makeErrorToast(data.message));
      }
    },
    toggleEditSideBar(value) {
      this.CLEAR_QUESTION();
      this.editQuestionId = undefined;
      this.openEditSideBar = value;
    },
    toggleAddSideBar(value) {
      this.CLEAR_QUESTION();
      this.questionTypeComp = undefined;
      this.sideBarTitle = "";
      this.openAddSideBar = value;
    },
    deleteQuestion(question) {
      this.$bvModal
        .msgBoxConfirm("Are you sure you want to delete this question ?", {
          title: "Delete Question",
          size: "sm",
          okVariant: "primary",
          okTitle: "Delete",
          cancelTitle: "Cancel",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if (value) {
            try {
              const { id, form_id } = question;
              await formsService.deleteProgramFormQuestion(this.$route.params.id, form_id, id);
              this.$toast(
                makeSuccessToast("Survey Question deleted successfully.")
              );
              this.fetchSurvey();
            } catch (e) {
              const { data } = e.response;
              this.$log.error(e);
              this.$toast(makeErrorToast(data.message));
            }
          }
        });
    },
    createQuestion(formId, question) {
      if (this.$route.meta.isProgramRoute) {
        return formsService.createProgramFormQuestion(
          this.$route.params.id,
          formId,
          question
        );
      }

      return formsService.createLibraryFormQuestion(formId, question);
    },
    async createSurveyQuestion(data) {
      const { question } = data;
      const formId = this.$route.params.survey;
      try {
        this.isUpdating = true;
        await this.createQuestion(formId, question);
        this.fetchSurvey();
        this.$toast(makeSuccessToast("Question created successfully."));
        this.clearForm();
      } catch (e) {
        const { data } = e.response;
        this.$log.error(e);
        this.$toast(makeErrorToast(data.message));
      } finally {
        this.isUpdating = false;
      }
    },
    updateQuestion(formId, questionId, question) {
      if (this.$route.meta.isProgramRoute) {
        return formsService.updateProgramFormQuestion(
          this.$route.params.id,
          formId,
          questionId,
          question
        );
      }

      return formsService.updateLibraryFormQuestion(
        formId,
        questionId,
        question
      );
    },
    async updateSurveyQuestion(data) {
      const { question } = data;
      const { id } = question;
      const formId = this.$route.params.survey;
      try {
        this.isUpdating = true;
        await this.updateQuestion(formId, id, question);
        this.fetchSurvey();
        this.$toast(makeSuccessToast("Question updated successfully."));
        this.clearForm();
      } catch (e) {
        const { data } = e.response;
        this.$log.error(e);
        this.$toast(makeErrorToast(data.message));
      } finally {
        this.isUpdating = false;
      }
    },
    redirectBack() {
      if (this.viewMode === "champion") {
        this.$router.push({ name: "champion-program-surveys-list" });
      } else {
        this.$router.push({ name: "admin-survey-list" });
      }
    },
    ...mapMutations("questions", ["CLEAR_QUESTION"]),
    getAvailability(action) {
      return useGetAvailability(action);
    },
  },
};
</script>


<style lang="scss">
$sidebar-header-text-color: #808080;
.b-sidebar > .b-sidebar-header {
  flex-direction: row-reverse;
  background: #f3f3f3;
  color: $sidebar-header-text-color;

  #add-questions-sidebar___title__ {
    font-size: 0.8em;
    flex: 2;
  }
}
.card .card {
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;
}

.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.move-icon {
  cursor: pointer;
}
.overflow-scroll {
  overflow-x: scroll;
}
</style>
