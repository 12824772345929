<template>
  <survey-form view-mode="champion" />
</template>

<script>
import SurveyForm from "@/views/components/forms/surveys/SurveysForm.vue";

export default {
  components: {
    SurveyForm,
  },
};
</script>